import React from "react"
import Layout from "../components/layout"
import AboutPMI from "../components/aboutPMI"

const AboutPmi = () => {
  return (
    <Layout>
      <AboutPMI />
    </Layout>
  )
}

export default AboutPmi
